import { useEffect, lazy, Suspense } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import jwt_decode from 'jwt-decode';
import axios from 'axios';
import './App.css';
import { store, persistor } from './state';

import AppModal from './components/common/AppModal';
import { deleteAccessToken, setCurrentUser } from './state/action-creators';

import Login from './components/auth/Login';
import PrivateRoute from './components/common/PrivateRoute';
import ParentLayout from './components/common/ParentLayout';

import Dashboard from './components/dashboard';
import EcomLead from './components/ecom-lead';
import Journal from './components/bo-journal';
import Logs from './components/server-logs';
import Crypto from './components/crypto';
import NotFound from './components/common/NotFound';
import ImageToolsHub from './components/imagetoolshub';

//set axios baseURL
axios.defaults.headers.post['Content-Type'] = 'application/json';

const jwt_token = localStorage.jwt_token;
// Check for token
if (jwt_token) {
  // Decode token and get user info and exp
  let decoded;

  try {
    decoded = jwt_decode(jwt_token);
    // Check for expired token
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
      // Logout user

      store.dispatch(deleteAccessToken());

      // Redirect to login/home
      window.location.href = '/';
    } else {
      // Set user and isAuthenticated
      store.dispatch(setCurrentUser(jwt_token));
    }
  } catch (err) {
    console.log('Error: ', err.message);
    store.dispatch(deleteAccessToken());
  }
}

const App = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router>
          <div className="app">
            <AppModal />
            <Routes>
              <Route path="/login" element={<Login />} />

              {/* for sider */}
              <Route
                path="/*"
                element={
                  <PrivateRoute>
                    <ParentLayout />
                  </PrivateRoute>
                }
              >
                {/* add 404 Not Found Page*/}
                <Route path="*" element={<NotFound />} />

                <Route path="" element={<Journal />} />
                <Route path="journal" element={<Journal />} />
                <Route path="logs" element={<Logs />} />
                <Route path="dashboard" element={<Dashboard />} />
                <Route path="image-tools-hub" element={<ImageToolsHub />} />
                {/* <Route path="ecom" element={<EcomLead />} /> */}
                {/* <Route path="crypto" element={<Crypto />} /> */}
              </Route>
            </Routes>
          </div>
        </Router>
      </PersistGate>
    </Provider>
  );
};

export default App;
