import { Tooltip, Tabs } from 'antd';
import moment from 'moment';
import { useImmer } from 'use-immer';
import CustomerStat from './CustomerStat';
import VisitorReviews from './VisitorReviews';

const ImageToolsHub = (props) => {
  const [activeTab, setActiveTab] = useImmer(1);

  return (
    <div style={{ padding: '4px 10px' }}>
      <Tabs
        defaultActiveKey={`${activeTab}`}
        onChange={(key) => setActiveTab(key)}
      >
        {/* stats */}
        <Tabs.TabPane tab="Customers" key="1">
          {/* customer stat */}
          {`${activeTab}` === '1' && <CustomerStat />}
        </Tabs.TabPane>
        {/* reviews */}
        <Tabs.TabPane tab="Reviews" key="2">
          {`${activeTab}` === '2' && <VisitorReviews />}
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
};

export default ImageToolsHub;

const sortByArr = [
  {
    type: 'Rating Desc',
    sort: '-rating,-updatedAt',
  },

  {
    type: 'Rating Asc',
    sort: 'rating,-updatedAt',
  },

  { type: 'Newest Updated Date', sort: '-updatedAt' },
  { type: 'Oldest Updated Date', sort: 'updatedAt' },

  { type: 'Newest Created Date', sort: '-createdAt' },
  { type: 'Oldest Created Date', sort: 'createdAt' },
];

const COLUMNS = [
  {
    title: '_id',
    dataIndex: '_id',
    key: '_id',
    render: (text, record) => (
      <Tooltip title={`${text}`.length > 3 && `${text}`} placement="topLeft">
        <span>
          {`${text}`.substring(0, 3)}
          {`${text}`.length > 3 && '..'}
        </span>
      </Tooltip>
    ),
  },
  {
    title: 'Feedback',
    dataIndex: 'feedback',
    key: 'feedback',
    render: (text, record) => (
      <Tooltip title={`${text}`.length > 25 && `${text}`} placement="topLeft">
        <span>
          {`${text}`.substring(0, 25)}
          {`${text}`.length > 25 && '...'}
        </span>
      </Tooltip>
    ),
  },

  {
    title: 'Rating',
    dataIndex: 'rating',
    key: 'rating',
  },

  {
    title: 'Name',
    dataIndex: 'user_name',
    key: 'user_name',
    render: (text, record) => (
      <span>
        {`${text}`.substring(0, 25)}
        {`${text}`.length > 25 && '...'}
      </span>
    ),
  },

  {
    title: 'User Email',
    dataIndex: 'user_email',
    key: 'user_email',
  },

  {
    title: 'User IP',
    dataIndex: 'user_ip',
    key: 'user_ip',
    render: (text, record) => (
      <>
        {text && (
          <a
            href={`https://whatismyipaddress.com/ip/${text}`}
            rel="noopener noreferrer"
            target="_blank"
          >
            {`${text}`}
          </a>
        )}
      </>
    ),
  },

  {
    title: 'href',
    dataIndex: 'href',
    key: 'href',
    render: (text, record) => (
      <a href={text} rel="noopener noreferrer" target="_blank">
        {`${text}`.substring(0, 25)}
        {`${text}`.length > 25 && '...'}
      </a>
    ),
  },

  {
    title: 'Created',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (text, record) => <span>{moment(text).fromNow(true)} ago</span>,
  },
  {
    title: 'Updated',
    dataIndex: 'updatedAt',
    key: 'updatedAt',
    render: (text, record) => <span>{moment(text).fromNow(true)} ago</span>,
  },
];
